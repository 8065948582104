<template>
  <div class="battery">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full">
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('asset')"
            :headers="headers"
            :tableData="$store.state.assets.assets"
            :loading="$store.state.assets.loading"
            createName="Asset-Detail"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";

export default {
  name: "Asset",
  components: {
    ListTable,
    SlideUp
  },
  data() {
    return {
      headers: [
        {
          name: "#",
          key: "index",
          type: "hidden",
          linkInfo: { name: "Asset-Detail", routeKey: "id", varKey: "id" }
        },
        { name: "name", key: "name", type: "" },
        { name: "hub", key: "hub.name", type: "" },
        { name: "notes", key: "notes", type: "" }
      ]
    };
  },
  mounted() {
    this.$store.dispatch("assets/getAllAssets");
  }
};
</script>
